<div class="container page-content">
  <div class="row">
    <div class="col-3">
      <div class="card">
        <div class="card-header">{{ "settings" | i18n }}</div>
        <div class="list-group list-group-flush" *ngIf="organization$ | async as org">
          <a
            routerLink="account"
            class="list-group-item"
            routerLinkActive="active"
            *ngIf="org.isOwner"
          >
            {{ "organizationInfo" | i18n }}
          </a>
          <a
            routerLink="policies"
            class="list-group-item"
            routerLinkActive="active"
            *ngIf="org.canManagePolicies"
          >
            {{ "policies" | i18n }}
          </a>
          <a
            routerLink="two-factor"
            class="list-group-item"
            routerLinkActive="active"
            *ngIf="org.use2fa && org.isOwner"
          >
            {{ "twoStepLogin" | i18n }}
          </a>
          <a
            routerLink="tools/import"
            class="list-group-item"
            routerLinkActive="active"
            *ngIf="org.canAccessImportExport"
          >
            {{ "importData" | i18n }}
          </a>
          <a
            routerLink="tools/export"
            class="list-group-item"
            routerLinkActive="active"
            *ngIf="org.canAccessImportExport"
          >
            {{ "exportVault" | i18n }}
          </a>
          <a
            routerLink="domain-verification"
            class="list-group-item"
            routerLinkActive="active"
            *ngIf="org?.canManageDomainVerification"
          >
            {{ "domainVerification" | i18n }}
          </a>
          <a
            routerLink="sso"
            class="list-group-item"
            routerLinkActive="active"
            *ngIf="org.canManageSso"
          >
            {{ "singleSignOn" | i18n }}
          </a>
          <ng-container *appIfFeature="FeatureFlag.TrustedDeviceEncryption">
            <a
              routerLink="device-approvals"
              class="list-group-item"
              routerLinkActive="active"
              *ngIf="org.canManageUsersPassword"
            >
              {{ "deviceApprovals" | i18n }}
            </a>
          </ng-container>
          <a
            routerLink="scim"
            class="list-group-item"
            routerLinkActive="active"
            *ngIf="org.canManageScim"
          >
            {{ "scim" | i18n }}
          </a>
        </div>
      </div>
    </div>
    <div class="col-9">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
